import type { RouterOutputs } from '@centrito/app/api/trpc'
import { trpc } from '@centrito/app/api/trpc'

export type DiscountGameTrpcData = RouterOutputs['promotions']['priceChop']['getDiscountGame']

export const useGetDiscountGame = (): {
  data: DiscountGameTrpcData | undefined
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
} => {
  return trpc.promotions.priceChop.getDiscountGame.useQuery(undefined, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: false,
    throwOnError: false,
  })
}
